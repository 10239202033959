.container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .section {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .title {
    display: inline-block;
    background-color: #5e3cb6; /* Adjust color as needed */
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .section:nth-child(2) .title {
    background-color: #d94662; /* Adjust color as needed */
  }
  
  .text {
    font-size: 1.1em;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 600px;
  }
  