/* Footer.module.css */

.footerContainer {
    width: 100%;
    background-color: #f1f1f1;
    padding: 35px 0; /* Espaciado interior superior e inferior */
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center; /* Centrar el contenido horizontalmente */
    top: 101%;
}

.socialMedia {
    display: flex;
    justify-content: center; /* Centrar los elementos horizontalmente */
}

.socialIcon {
    margin: 0 10px; /* Espaciado entre los iconos de redes sociales */
    text-decoration: none; /* Eliminar subrayado */
}

.socialImage {
    width: 24px; /* Tamaño de las imágenes de los iconos */
    height: 24px; /* Tamaño de las imágenes de los iconos */
}
