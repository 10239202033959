.containerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;

}


.slider {
  width: 100%; /* Asegura que el slider ocupe todo el ancho */
  margin-right: 20px;
  margin: 0 auto; /* Asegura que el slider esté centrado */
  
}

.cardWrapper {
  display: flex;
  padding: 10px; /* Añade un espacio alrededor de cada carta */
  margin-left: 65px;
}


@media screen and (max-width: 1440px) {

  .containerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 700px;
  
  }

  .slider {
    width: 900px; /* Mantiene el slider en 100% */
  }

  .slider {
    width: 110%; /* Asegura que el slider ocupe todo el ancho */
    margin-right: 20px;
    margin: 0 auto; /* Asegura que el slider esté centrado */
    
  }
  .cardWrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Asegura que las cartas ocupen todo el ancho */
    margin-left: 0;
    padding: 10px;
    gap: 10px; /* Ajusta el espacio entre cartas */
  }
  .cardWrapper > div {
    width: 100%; /* Asegura que las cartas ocupen todo el ancho disponible */
    margin: 0; /* Elimina el margen para evitar desplazamiento lateral */
  }

}
/* Para pantallas más pequeñas */
@media (max-width: 900px) {
  .slider {
    width: 100%; /* Mantiene el slider en 100% */
  }

  .cardWrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Asegura que las cartas ocupen todo el ancho */
    margin-left: 0;
    padding: 10px;
    gap: 10px; /* Ajusta el espacio entre cartas */
  }

  .cardWrapper > div {
    width: 100%; /* Asegura que las cartas ocupen todo el ancho disponible */
    margin: 0; /* Elimina el margen para evitar desplazamiento lateral */
  }
}

/* Para pantallas muy pequeñas (menores a 600px) */
@media (max-width: 600px) {
  .slider {
    width: 110%; 

    margin: 0; /* Asegura que el slider esté centrado */
  }

  .cardWrapper {
    width: 100%;
    gap: 5px; /* Ajusta el espaciado entre cartas */
  }

  .cardWrapper > div {
    width: 100%; /* Asegura que las cartas ocupen todo el ancho en pantallas pequeñas */
  }
}
