.carouselContainer {
  width: 100%;
  height: 700px; /* Ajusta esta altura según el tamaño deseado para tu carrusel */
  overflow: hidden; /* Asegúrate de que cualquier contenido fuera del área visible sea ocultado */
  position: relative;

}


.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.imageContainer {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

}

.carousel-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain; /* Ajusta la imagen para mantener la proporción sin recortarla */
  z-index: 1; /* Asegura que la imagen esté delante */
}

/* Estilos para el modo fullscreen */
.fullScreen {
  width: 100vw; /* Ocupa todo el ancho de la ventana */
  height: 100vh; /* Ocupa todo el alto de la ventana */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff; /* Fondo blanco en modo fullscreen */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

}
.fullScreenSwiper {
  width: 100vw; /* Ocupa todo el ancho de la pantalla en fullscreen */
  height: 100vh; /* Ocupa todo el alto de la pantalla en fullscreen */
}
.fullScreen .imageContainer {
  height: 100vh; /* En fullscreen ocupa el 100% de la altura de la pantalla */
}


.fullScreenImage {
  width: auto;
  height: 100%; /* Ocupa todo el alto de la pantalla en fullscreen */
  object-fit: contain; /* Mantiene la proporción de la imagen */
 
}

.ContainerIntro {
  margin-top: 50px;
  margin-left: 10px;
  width: 850px;
  display: flex;
  flex-direction: column; /* Cambia la dirección del flujo a columnas */
  border: 1px solid rgb(131, 125, 125);
  padding: 15px;
  border-radius: 10px;
  transition: border-color 0.5s;
}

.ContainerIntro:hover {
  border-color: #3D2F87; /* Cambia el color del borde al pasar el mouse sobre el contenedor */
}


.ContainerSell {
  width: 100%; /* Ancho deseado para el contenedor */
  
}

.ContainerSell h3 {
  margin-bottom: 0.5rem; /* Espacio entre el título "Venta" y el título de la propiedad */
}

.ContainerSell h1 {
  font-weight: bold; /* Hace el título de la propiedad en negrita */
}

.ContainerSell p {
  font-weight: bold; /* Hace el precio en negrita */
 font-size: 35px;
}

.ContainerPrincipalData {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Alinea los elementos al principio del contenedor */
}

.ContainerPrincipalData p {
 font-size: 16px;
  margin-right: 4%; /* Añade un pequeño margen horizontal entre los elementos */
}

h3 {
  font-size: 12px;
  color:darkgray;
}
.SellTitle {
  display: inline-block; /* Hace que el contenedor solo ocupe el ancho del contenido */
  border: 1px solid darkgray; /* Aplica el borde alrededor del título */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-radius: 5px;
}


.descriptionContainer {
  margin-top: 10px;
  margin-left: 10px;
  width: auto;
  display: flex;
  flex-direction: column; /* Cambia la dirección del flujo a columnas */
  border: 1px solid rgb(131, 125, 125);
  padding: 15px;
  border-radius: 10px;
  transition: border-color 0.5s;
}

.descriptionContainer:hover {
  border-color: #3D2F87; /* Cambia el color del borde al pasar el mouse sobre el contenedor */
}


.description {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  flex-basis: 100%;
}

.otherDataContainer {
  display: flex;
  flex-wrap: wrap;
}

.otherData {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.pairContainer {
  width: 50%;
  padding: 1px;
  box-sizing: border-box;
}

.pairContainer p {
  margin: 0;
  padding: 5px 0;
  line-height: 1.5;
}






.dataContainer {
  margin-top: 20px;
}

.data {
  display: flex;
  flex-wrap: wrap;
}

.data ul {
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.data li {
  width: calc(50% - 20px); /* Ancho de cada columna */
  margin: 5px;
  box-sizing: border-box;
}

.ubicationContainer{
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column; /* Cambia la dirección del flujo a columnas */
  border: 1px solid rgb(131, 125, 125);
  padding: 15px;
  border-radius: 10px;
  transition: border-color 0.5s;
}


.ubicationContainer:hover {
  border-color: #3D2F87; /* Cambia el color del borde al pasar el mouse sobre el contenedor */
}

.dataUbication {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se muevan a una nueva línea si no hay espacio */
  gap: 5px; /* Añade un pequeño espacio entre los elementos */
  justify-content: flex-start; /* Alinea los elementos al principio */
  align-items: center; /* Alinea los elementos verticalmente al centro */
}

.dataUbication p {
  padding-right: 5px;
  margin: 0; /* Elimina cualquier margen adicional que pueda causar desajustes */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}
.sellerContainer {
  margin-top: 10px;
  margin-left: 10px;
  width: 400px;
  display: flex;
  flex-direction: column; /* Cambia la dirección del flujo a columnas */
  border: 1px solid rgb(131, 125, 125);
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
  transition: border-color 0.5s;
}

.sellerContainer:hover {
  border-color: #3D2F87; /* Cambia el color del borde al pasar el mouse sobre el contenedor */
}


.sellerDetails{
  flex-grow: 1;
  padding: 10px; 
  display: flex;
}

.sellerimg {
  width: 50px; /* Tamaño deseado de la imagen */
  height: 50px;
  border-radius: 50%; /* Para hacer la imagen redondeada */
  display: flex; 
  align-items: center;
}
.vendedorName {
  display: flex; 
  align-items: center;
  margin-left: 5%;
  font-weight: bold;
  font-size: 18px;
}

.contacto {
  display: flex;
  align-items: center; /* Alinea verticalmente el contenido */
  justify-content: space-between; /* Distribuye el espacio entre los elementos */
}

.contacto h1 {
  font-size: 26px;
  margin-right: auto; /* Mueve el título hacia la izquierda */
}

.redes {
  display: flex; /* Utiliza flexbox para alinear elementos hijos */
  align-items: center; /* Alinea verticalmente los elementos hijos */
  margin-right: 10px; /* Margen derecho automático para alinear a la derecha */
}

.email,
.WhatsApp {
  width: 40px;
  height: auto;
  margin-left: 10px; /* Margen izquierdo entre las imágenes */
}

.formContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Estilo de la tarjeta */
.card {
  width: auto;
  max-width: 400px; /* Ancho máximo de la tarjeta */
  padding: 0px;
  border-radius: 10px;


}

/* Título */
.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

/* Texto */
.text {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

/* Formulario */
.mailForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Etiqueta del input */
.mailLabel {
  font-size: 16px;
  margin-bottom: 8px;
}

/* Input de texto y textarea */
.inputText {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 16px;
  box-sizing: border-box; /* Incluye borde y relleno en el ancho especificado */
  margin: 0;
}

/* Botón de envío */
.btn {
  margin-top: 3%;
  width: 100%;
  padding: 15px;
  background-color: #3D2F87;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: darkblue;
}

.container {
  display: flex; /* Utiliza flexbox para alinear los elementos en fila */
  justify-content: center; /* Centra los elementos horizontalmente */
}

.leftContent {
  margin-left: 50%; 
}

.rightContent {
  flex: 1; /* Hace que los contenedores ocupen el mismo espacio */
  margin-right: 50%; /* Agrega un pequeño espacio entre los contenedores */
  margin-top: 40px;
}

.link {
  text-decoration: none;
  color: black;
}

.matricula{
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .carouselContainer {
    height: 550px; /* Ajusta la altura para pantallas más pequeñas */
  }

  .imageContainer {
    height: 550px; /* Asegúrate de que el contenedor de imagen también se ajuste */
  }
  
  .carousel-img {
    object-fit: contain; /* Asegura que la imagen mantenga su proporción en pantallas pequeñas */
  }
}

@media screen and (max-width: 550px) {
  .carouselContainer {
    height: 400px; /* Ajusta la altura para pantallas más pequeñas */
  }

  .imageContainer {
    height: 400px; /* Asegúrate de que el contenedor de imagen también se ajuste */
  }
  
  .carousel-img {
    object-fit: contain; /* Asegura que la imagen mantenga su proporción en pantallas pequeñas */
  }
  .container{
    display: flex;
    flex-direction: column;
  }

  .leftContent {
    margin-left: 0%; 
  }
  .ContainerIntro {
    width: auto;
  }

  .sellerContainer {
    margin-top: 0px;
    margin-left: 0px;
    width: 199%;
  }
  
}



