/* Login.module.css */

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.card {
    width: 450px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content {
    margin-bottom: 20px;
    padding-top: 1px;
    padding-left: 10%;
    padding-right: 10%;
    
   
}

.title {
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px;
}

.formGroup {
    margin-bottom: 20px;
  
}
.form {
    width: 90%; /* Ancho del formulario */
    margin: auto; /* Centrar el formulario */
    
}

.inputForm {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Incluye borde y relleno en el ancho especificado */
    margin: 0;
}

.buttonSubmit {
    width: 100%; /* Ancho del botón igual al del contenedor del formulario */
    margin-top: 5%; /* Espacio entre el botón y los inputs */
    padding: 15px;
    background-color: #3D2F87;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.forgotPassword {
    text-align: center;
    color: rgb(31, 30, 30);
    text-decoration: none;
}

.googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-top: 60px; 
}

.googleButton button {
    border: 1px solid #3D2F87;
    padding: 15px;
    color: #3D2F87;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
}

.googleButton button img {
    width: 24px;
    margin-right: 10px;
}

.forgotPasswordLink{
    color: #3D2F87;
    text-decoration: none;
    font-size: 13px;
}

.registerLink {
    margin-top: 30px; 
    text-align: center; /* Centra el texto horizontalmente */
    font-size: 16px; /* Cambia el tamaño de la fuente */
}

