.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
    background: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .content {
    text-align: center;
    padding: 20px;
  }
  
  .title {
    font-size: 3em;
    color: #3D2F87; /* Adjust color as needed */
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    font-size: 1em;
  }
  
  .list li {
    margin: 10px 0;
    position: relative;
    padding-left: 25px;
  }
  
  .list li::before {
    content: "→";
    position: absolute;
    left: 0;
    color: #d94662; /* Adjust color as needed */
  }
  
  .contact {
    margin-bottom: 20px;
  }
  
  .contact p {
    margin: 0;
    font-size: 1em;
    color: #3D2F87; /* Adjust color as needed */
  }
  
  .email {
    font-size: 1.2em;
    color: #3D2F87; /* Adjust color as needed */
    text-decoration: none;
  }
  
  .footer {
    background: #3D2F87; /* Adjust color as needed */
    width: 100%;
    padding: 10px 0;
    text-align: center;
    border-radius: 0 0 20px 20px;
  }
  .logoContainer {
    position: relative;
    display: inline-block;
  }
  
  .logo {
    position: relative;
  z-index: 2;
    height: 40px; /* Adjust size as needed */
  }

  .logoContainer::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px; /* Ajusta el tamaño según sea necesario */
    height: 50px; /* Ajusta el tamaño según sea necesario */
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Asegura que el círculo esté detrás del logo */
  }
  