.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem;
    border-top: 1px solid #3D2F87;
    border-bottom: 5px solid #A83E52 ;
}
.img{
    width: 70px;
}

.leftSection {
    flex: 0.5;
    margin: 0; /* Elimina los márgenes externos */
    position: absolute; /* Cambia la posición a absoluta */
    top: 15px;
   left: 1%; /* Coloca el título en el 50% del ancho del contenedor */
}

.navLinks {
    flex: 2;
    display: flex;
    justify-content: center;
    gap: 1rem;
    position: absolute; /* Cambia la posición a absoluta */
    left: 50%; /* Coloca el título en el 50% del ancho del contenedor */
    transform: translateX(-50%); /* Centra el título */
    
}

.rightSection {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: absolute; /* Cambia la posición a absoluta */
   right: 3%; /* Coloca el título en el 50% del ancho del contenedor */   
}

/* Otros estilos */
.navContainer {
    background-color: #f1f1f1;
    color: #3D2F87;
}

.navLinks a {
    color: rgb(31, 30, 30);
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}
.navLinks a.active {
    color: #3D2F87;
    border-bottom: 1px solid #3D2F87;
    margin-bottom: -5px; 
}

.navLinks a:hover {
   color: black;
}

.activeLink {
    text-decoration: underline;
    color: #3D2F87; /* Cambiar el color del texto al azul */
}
.butonexit {
    /* Estilos por defecto */
    border: 1px solid #3D2F87;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: transparent;
    padding: 8px 16px;
    text-decoration: none;
    color: #3D2F87;
    transition: background-color 0.3s ease; /* Transición suave del color de fondo */
  
    /* Agregamos la regla para el hover */
  }
  .butonexit:hover {
    background-color: rgb(189, 189, 233); /* Cambiamos el color de fondo al pasar el mouse */
  }
  .navbar-collapse {
    /* Estilos para el desplegable del Navbar */
    justify-content: center; /* Ejemplo */
  }

  

  /* Establecer el tamaño de fuente para pantallas más grandes */
@media (min-width: 992px) {
  :root {
    --bs-nav-link-font-size: 23px; /* Tamaño de fuente más grande para pantallas grandes */
  }
  .navCenter {
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 5px solid #3D2F87;
    border-bottom: 5px solid #A83E52 ;
}

}

/* Establecer el tamaño de fuente para pantallas más pequeñas */
@media (max-width: 991px) {
  :root {
    --bs-nav-link-font-size: 17px; /* Tamaño de fuente más pequeño para pantallas pequeñas */
  }
  .logo {
    max-width: 35px;
  }
  .navCenter {
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
   
    border-top: 3px solid #3D2F87;
}


}