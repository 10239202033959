.containerBanner {
    height: 300px;
    overflow: hidden;
    z-index: 1;
  }
  
  .banner {
    width: 100%;
    object-fit: cover; /* La imagen se recorta para llenar el contenedor manteniendo su proporción */
    margin-top: -200px;
  }
  
  .container {
    padding: 20px;
    position: relative; /* Añadir esta propiedad para posicionar los elementos de manera relativa */
  }
  .officeDetail{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgb(184, 184, 184);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    z-index: 10;
    margin-top: 10px; /* Mueve la tarjeta hacia arriba para superponerse al banner */
    position: relative; /* Añadir esta propiedad para controlar mejor la posición */
    margin-left: 30px;
  
  }
  
  .detail {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgb(184, 184, 184);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    z-index: 10;
    margin-top: -100px; /* Mueve la tarjeta hacia arriba para superponerse al banner */
    position: relative; /* Añadir esta propiedad para controlar mejor la posición */
    margin-left: 30px;
  }
  
  .detail h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .photo {
    width: 150px; /* Ajusta el tamaño según tus necesidades */
    height: 150px; /* Ajusta el tamaño según tus necesidades */
    border-radius: 50%; /* Hace la imagen circular */
    margin-bottom: 20px;
    object-fit: cover; /* Asegura que la imagen se recorte para llenar el contenedor circular */
  }
  
  .detail p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .detail p:last-child {
    margin-bottom: 0;
  }

  
.officeDetail {
    margin-top: 20px;
  }
  
  .propertiesContainer {
    margin-top: 20px;
    margin-left: 45px;
  }

  .properties{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columnas */
    gap: 10px; /* Espacio entre las tarjetas */
  }
  
  .propertyCard {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
  }
  
  .propertyCard h3 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }
  
  .propertyCard p {
    margin: 5px 0;
  }
  
  .detailSeller{
    display: flex;
  }

  .propertyCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 10px;
    max-width: 300px;
    width: 100%;
  }
  
  .propertyCard .carousel {
    height: 150px; /* Adjust the height as needed */
  }
  
  .propertyCard .carousel img {
    width: 100%;
    height: 250px; /* Match the height to the carousel height */
    object-fit: cover;
  }

  .image{
    width: 350px;
    height: 200px;
  }
  
  .propertyInfo {
    padding: 10px;
    text-align: left;
  }
  
  .propertyInfo h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .propertyInfo p {
    font-size: 14px;
    margin: 0;
  }
  .link{
    text-decoration: none;
    color: black;
  }

  .containerCarga{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Altura completa de la pantalla */
    text-align: center; /* Centra el texto */
  }

  .carga{
    width: 400px; /* Ajusta el tamaño de la imagen según lo necesites */
  
  }

  @media (max-width: 768px) {
    .officeDetail{
      margin-left: 0px;
    }

    .detail{
      margin-left: 0px;
    }
    .containerBanner {
      height: 300px;
      overflow: hidden;
      z-index: 1;
    }
    
    .banner {
      width: 100%;
      object-fit: cover; /* La imagen se recorta para llenar el contenedor manteniendo su proporción */
      margin-top: -100px;
    }
    .detailSeller{
      flex-direction: column;
    }

    .properties{
      grid-template-columns: repeat(2, 1fr); 
      flex-direction: column;
    }

    .dataContainer {
      display: flex;
      flex-direction: column; /* Mantén la disposición en fila por defecto */
      justify-content: center;
      align-items: center; /* Alinea los elementos hacia el inicio */
    }

  }

  @media (max-width: 480px) {
    .containerBanner {
      min-height: 150px; /* Reduce la altura mínima en móviles */
    }
  
    .banner {
      height: auto; /* Ajusta la altura de la imagen según el contenido */
      margin-top: 0; /* Elimina el margin-top en móviles */
    }
    
  .detailSeller{
    flex-direction: column;
  }
  
  .properties{
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 0px; /* Espacio entre las tarjetas */
    flex-direction: column;
  }

  .propertiesContainer {
    margin-top: 20px;
    margin-left: 0px;
  }
  }