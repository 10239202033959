/* Card.module.css */

.card {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex; 
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Cambiado a 'hidden' para evitar desbordamiento */
}
  
  .link {
    text-decoration: none;
    color: #333;
    overflow: hidden;
    box-sizing: border-box;
    overflow: hidden; /* Cambiado a 'hidden' para evitar desbordamiento */
  }
  
  .imageContainer {
    width: 100%; /* El contenedor ocupará todo el ancho disponible */
    height: 500px; /* Ajusta este valor según tus necesidades */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Oculta cualquier parte de la imagen que se salga del contenedor */
    background-color: #f0f0f0; /* Color de fondo del contenedor */
    position: relative;
    border-radius: 10px; /* Esquinas redondeadas del contenedor */
  }
  
  .carousel-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ajusta la imagen para que se vea completa dentro del contenedor */
    transition: transform 0.5s ease; /* Transición suave para cualquier transformación */
    display: block;
    margin: auto; /* Centra la imagen dentro del contenedor */
  }
  
  
  
  .sellerimg {
    width: 50px; /* Tamaño deseado de la imagen */
    height: 50px;
    border-radius: 50%; /* Para hacer la imagen redondeada */
  }
  
  .sellerContainer {
    display: flex; /* Utilizamos flexbox para colocar los elementos en línea */
    align-items: center; /* Alineamos verticalmente los elementos al centro */
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap; /* Permite que los elementos se ajusten en pantallas pequeñas */
    gap: 10px; /* Añade espacio entre los elementos */
}

  .sellerDetails{
    flex-grow: 1;
    padding: 10px; 
    display: flex;
  }

  
  .detailsCardContainer {
    padding-left: 15px;
    font-size: 16px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 3000px !important;
    overflow: hidden;
  }

  .price{
    font-size: 46px;
    color: #333;
    margin-top: 5px;
    margin-bottom: -10px;
    font-weight: bold;
  }

  .street{
    font-size: 20px;
    color: #333;
 
  
  }
  .locality{
    font-size: 16px;
    color: #8a8a8a;
    
  }

  .WhatsApp{
    width: 40px;
    padding: 10px;
  }

  .email{
    width: 40px;
    padding: 10px;
  }

  .comunication{
   align-items: end;
   text-align: end;
  }

  .vendedorName{
    font-size: 20px;
    padding-right: 20px;
  }


  .baño{
    width: 50px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  }

  .datos{
    display: flex;
  }

  .tituloContainer {
    max-width: 100%; /* Asegura que el contenedor no exceda el ancho de la tarjeta */
    overflow: hidden; /* Oculta cualquier texto que se desborde */
    text-align: left; /* Alinea el texto a la izquierda */
    display: flex;
  }
  
  /* El título en sí */
  .titulo {
    font-size: 1rem; /* Tamaño de fuente moderado */
    line-height: 1.4; /* Mejora la legibilidad */
    margin: 0;
    white-space: nowrap; /* Evita que el texto salte de línea */
    overflow: hidden; /* Oculta cualquier parte del texto que se desborde */
    text-overflow: ellipsis; /* Agrega "..." al final del texto si es muy largo */
    display: inline-block; /* Asegura que el título se comporte como un bloque en línea */
  }
/* Ajustes para pantallas medianas de tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .card {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
  }

  .image {
      max-width: auto;
      max-height: 300px;
      object-fit: contain;
      background-color: #ccc;
      display: block;
      margin: auto; /* Centra la imagen horizontalmente */
      transition: transform 1s ease; /* Agrega una transición suave */
      border-radius: 10px;
  }

  .imageContainer {
      width: 100%;
      height: 300px; /* Ajusta este valor según tus necesidades */
  }

  .price {
      font-weight: bold;
      font-size: 36px;
  }

  .baño {
      width: 30px;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
  }

  .datos {
      flex-direction: row;
  }

  .tituloContainer {
    max-width: 100%; /* Asegura que el contenedor no exceda el ancho de la tarjeta en dispositivos móviles */
  }

  .titulo {
    font-size: 0.875rem; /* Disminuye ligeramente el tamaño del texto en pantallas pequeñas */
    line-height: 1.2;
    white-space: normal; /* Permite el salto de línea en pantallas pequeñas */
    word-wrap: break-word; /* Asegura que las palabras largas se ajusten */
  }

}

  @media (max-width: 767px) {
    .card{
      display: flex;
      flex-direction: column;
      margin-right: 5%;
    }

    .datos{
      flex-direction: column;
    }

    .image {
      max-width: auto;
      max-height: 200px;
      object-fit: contain;
      background-color: #ccc;
      display: block;
      margin: auto; /* Centra la imagen horizontalmente */
      transition: transform 1s ease; /* Agrega una transición suave */
      border-radius: 10px;
    }
    
    .imageContainer {
      width: 100%;
      height: 200px; /* Ajusta este valor según tus necesidades */
  }
  
  .price {
    font-weight: bold;
    font-size: 16px;
  }

  .baño{
    width: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }

  .datos {
    flex-direction: column; /* Alinea los elementos verticalmente */
    width: 100%; /* Asegura que los elementos ocupen todo el ancho disponible */
}

.sellerDetails {
  flex-direction: column; /* Alinea los elementos verticalmente */
  align-items: flex-start; /* Alinea los elementos al inicio */
  gap: 10px; /* Añade espacio entre los elementos */
}

.comunication {
  justify-content: flex-start; /* Alinea los íconos al inicio */
  gap: 10px; /* Añade espacio entre los íconos */
}

.tituloContainer {
  max-width: 100%; /* Asegura que el contenedor no exceda el ancho de la tarjeta en dispositivos móviles */
}

.titulo {
  font-size: 0.875rem; /* Disminuye ligeramente el tamaño del texto en pantallas pequeñas */
  line-height: 1.2;
  white-space: normal; /* Permite el salto de línea en pantallas pequeñas */
  word-wrap: break-word; /* Asegura que las palabras largas se ajusten */
}
  
.detailsCardContainer {
  width: auto !important;
 
}

  }
  