/* Home.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
  }
  
  .intro {
    border-top: 0px;
    background-image: url('../../Assets/departamento.jpg');
    background-size: cover;
    background-position: center;
    padding: 5%;
    border-radius: 8px;
    width: 100%;
    padding-bottom: 10%;
    position: relative;
    z-index: 1; /* Asegura que los elementos dentro de .intro estén por encima */
  }
  
  .intro::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1; /* Envía el pseudo-elemento detrás de los elementos hijos de .intro */
  }
  .title {
    margin-top: -1%;
    text-align: center;
    align-items: center;
    font-size: 50px;
    margin-bottom: 20px;
    color: #ffffff; /* Color del título */
  }
  
  .buttonsContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .button {
    font-size: 16px;
    margin: 0 10px;
    padding: 15px 30px;
    width: 20%; /* Ancho fijo para todos los botones */
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; 
    }
    
    .button:hover {
    background-color: #3D2F87;
    color: #fff;
    }
  .searchContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .searchInput {
    width: 30%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    
  }
  
  .propertyTypeDropdown {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .searchButton {
    padding: 15px 20px;
    background-color: #3D2F87; /* Color de fondo del botón */
    color: #fff; /* Color del texto del botón */
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .searchButton img {
    width: 20px; /* Ajusta el tamaño de la imagen según sea necesario */
    height: 20px;
    margin-right: 5px; /* Ajusta el margen entre la imagen y el texto según sea necesario */
}
  
  .searchButton:hover {
    background-color: darkblue; /* Color de fondo al pasar el mouse */
  }
  .declaracion{
    font-size: 0.8rem; /* Tamaño de la fuente pequeño */
    color: gray; /* Color de la fuente gris */
    text-align:justify; /* Texto centrado */
    margin: 20px 0; /* Espacio arriba y abajo */
  }
  

  @media screen and (max-width: 550px) {

    .buttonsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px; /* Adjust the gap as needed */
      margin: 20px 0;
    }
    
    .button {
        font-size: 12px;
        padding: 8px 15px; /* Ajustar el espaciado del texto dentro de los botones */
        width: 150px;
    }
    .searchContainer{
      flex-direction: column;
    }
    .searchInput{
      width: 260px;
      margin-bottom: 15px;
    }
  }