.container {
    display: flex;
    flex-direction: column; /* Aseguramos que los elementos se apilen verticalmente */
    padding: 20px;
  }
  
  .containerContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px; /* Espacio entre la oficina y las tarjetas de vendedores */
    margin-top: 20px; /* Añadimos margen superior entre el banner y el contenido */
  }
  
  .containerBanner {
    height: 300px;
    overflow: hidden;
    z-index: 1;
  }
  
  .banner {
    width: 100%;
    object-fit: cover; /* La imagen se recorta para llenar el contenedor manteniendo su proporción */
    margin-top: -200px;
  }
  
  .officeDetail{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgb(184, 184, 184);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    z-index: 10;
    margin-top: 10px; /* Mueve la tarjeta hacia arriba para superponerse al banner */
    position: relative; /* Añadir esta propiedad para controlar mejor la posición */
    margin-left: 30px;
  
  }
  
  
  .officeDetail p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .cardSellersContainer {
    flex: 2; /* El contenedor de tarjetas de vendedores ocupará el doble del espacio */
    display: flex;
    flex-direction: column;
  }

  .containerCarga{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Altura completa de la pantalla */
    text-align: center; /* Centra el texto */
  }

  .carga{
    width: 400px; /* Ajusta el tamaño de la imagen según lo necesites */
  
  }
  
  @media (max-width: 900px) {
    .containerContent {
      flex-direction: column; /* Los elementos se apilan verticalmente */
      align-items: center;    /* Alinea los elementos al centro */
    }
  
    .banner {
      margin-top: 0; /* Corrige el desplazamiento negativo en pantallas pequeñas */
      height: 200px; /* Ajusta la altura para pantallas más pequeñas */
    }
  
    .officeDetail {
      max-width: 80%;   /* Asegura que la carta de la oficina ocupe el ancho completo */
      margin-left: auto; /* Centra el div horizontalmente */
      margin-right: auto; /* Centra el div horizontalmente */
      text-align: center; /* Centra el texto dentro del div */
      padding: 20px;      /* Añade padding para mayor espaciado interno */
    }
  
    .cardSellersContainer {
      max-width: 100%; /* Asegura que el contenedor de las tarjetas de vendedores ocupe el ancho completo */
      margin-top: 20px; /* Añade margen entre las tarjetas de vendedores y la carta de la oficina */
    }
  }
  
  @media (max-width: 600px) {
    .containerBanner {
      height: 150px; /* Reduce la altura del banner para pantallas más pequeñas */
    }
  
    .banner {
      height: 150px; /* Asegura que la imagen del banner se ajuste bien */
    }
  
    .officeDetail {
      max-width: 80%;   /* Reduce el ancho para dispositivos muy pequeños */
      padding: 15px;    /* Reduce el padding en pantallas más pequeñas */
      font-size: 14px;  /* Ajusta el tamaño del texto */
      margin-left: auto; /* Centra el div horizontalmente */
      margin-right: auto; /* Centra el div horizontalmente */
      text-align: center; /* Asegura que el texto esté centrado */
    }
  }