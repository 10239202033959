/* Contenedor principal */
.registerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
margin-bottom: 5%;
}

/* Tarjeta */
.card {
  background-color: #fff;
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Contenido */
.content {
  margin-bottom: 20px;
}

/* Título */
.title {
  padding-left: 5%;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600; /* Peso de la fuente del título */
  color: #333; /* Color del título */
}

/* Subtítulo */
.subtitle {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500; /* Peso de la fuente del subtítulo */
  color: #333; /* Color del subtítulo */
}

/* Formulario */
.form {
  width: 100%;
  margin: auto;
}

/* Grupo de formulario */
.formGroup {
  margin-bottom: 15px;
  display: flex; /* Flexbox para colocar los elementos uno al lado del otro */
  flex-direction: column; /* Coloca los elementos en columnas */
  position: relative;

}

/* Input */
.inputForm {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Incluye borde y relleno en el ancho especificado */
  margin: 0;
}

/* Dropdown */
.prefixDropdown {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 30%;
  margin-right: 10px;
}

/* Botón de envío */
.buttonSubmit {
  width: 100%;
  margin-top: 5%;
  background-color: #3D2F87;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}


/* Enlace de registro */
.registerLink {
  margin-top: 20px;
  text-align: center;
}

.registerLink a {
  color: #3D2F87;
  text-decoration: none;
}

/* Contraseña olvidada */
.forgotPassword {
  text-align: center;
  margin-top: 20px;
}

.errorText {
  text-align: left;
  font-size: 12px; /* Tamaño de fuente pequeño */
  color: red; /* Color rojo */
  display: block;
}

.invalidInput .errorText {
  display: block; /* Asegura que el mensaje de error se muestre en una nueva línea */
}

.formNumber {
  display: flex;
  align-items: center;
}

.optionWithFlag {
  padding-left: 30px; /* Espacio para la imagen */
  background-repeat: no-repeat;
  background-position: left center; /* Posición de la imagen */
  background-image: url('../../Assets/bandera-arg.png'); /* Ruta de la imagen de la bandera de Argentina */
}