.carddesarrollo {
  position: relative;
  width: 90%;
  height: 500px;
  margin: auto;
  overflow: hidden;
  margin-top: 85px;
  display: flex;
  margin-right: 0;
  justify-content: flex-end;
  overflow: visible;
}

.backgroundimage {
  width: 90%;
  height: 400px; /* Altura deseada */
  object-fit: cover; /* Recorta la imagen manteniendo la proporción */
  display: block;
  margin-left: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.overlay {
  position: absolute;
  top: -20px;
  left: 80px;
  bottom: 0;
  background-color: #d3c3ae7a;
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: center; /* Centra los elementos verticalmente */
  text-align: center; /* Centra el texto */
  width: 450px;
  height: 450px;
  border-radius: 100%;
}

.isologo {
  position: absolute;
  top: 90px; /* Ajusta la posición según sea necesario */
  left: 90px; /* Ajusta la posición según sea necesario */
  width: 50px;
  height: auto;
}

.titulo {
  width: 300px;
  margin-bottom: 20px; /* Añade un margen inferior para separar del botón */
}

.verdesarrollosbtn {
  background-color: #4e2993; /* Color del botón */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  text-transform: uppercase;
}

.verdesarrollosbtn:hover {
  background-color: #3b2070; /* Color del botón al pasar el mouse */
}

/* Media Queries */
@media (max-width: 768px) {     

  .carddesarrollo {
      position: relative;
      width: 100%;
      height: 500px;
      max-width: auto;
      margin: auto;
      overflow: hidden;
      margin-top: 55px;
      margin-bottom: 1px;
    }

    .backgroundimage {
      width: 100%;
      height: 350px;
      display: block;
      border-radius: 10px;
    }
  .overlay {
    top: 0px;
    left: 0;
    max-width: none;
    width: 100%;
    height: 70%;
    background-color: #d3c3ae7a;
    align-items: center;
  }

  .titulo {
    font-size: 1.2em;
    text-align: center;
  }

  .text {
    font-size: 0.8em; /* Disminuye el tamaño de la fuente */
    line-height: 1.2; /* Reduce el interlineado para comprimir el texto */
    text-align: center; /* Justifica el texto para mejor presentación */
    width: 100%; /* Ajusta el ancho del contenedor de texto */
    max-width: 600px; /* Establece un ancho máximo */
    margin-bottom: 20px;
  }

  .verdesarrollosbtn {
    font-size: 0.9em;
    padding: 8px 16px;
  }
  .isologo {
    display: none; 
  }
}

@media (max-width: 480px) {
  .isologo {
    width: 40px;
    margin-bottom: 15px;
  }

  .h2 {
    font-size: 1.2em;
  }

  .p {
    font-size: 0.8em;
  }

  .verdesarrollosbtn {
    font-size: 0.8em;
    padding: 6px 12px;
  }
}
